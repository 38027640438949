<template>
  <div class="layout_common params-dict">
    <div class="main_box">
      <div class="main_box_con">
        <div class="search_flex">
          <div class="search_left">
            <inputComp
              :hval="name"
              @blur="nameBlur"
              isShowIcon
              placeholder="参数名查询"
            />
            <btnGroup @search="search" @reset="reset" />
            <btnComp @click="add('add')" v-permission="'新增'">新 增</btnComp>
          </div>
        </div>
        <tableComp
          :data="tableData"
          :col-configs="theadName"
          :total="total"
          :currentPage="curPage"
          :pageSize="pageSize"
          @handleSizeChange="handleSizeChange"
          @handleCurrentChange="handleCurrentChange"
        >
          <el-table-column
            slot="operate"
            label="操作"
            width="180"
            fixed="right"
          >
            <template v-slot="scope">
              <btnComp btnType="text" @click="detail(scope.row.code,scope.row.level)" v-permission="'参数值'"
                >参数值</btnComp
              >
              <btnComp btnType="text" @click="add('edit', scope.row)" v-permission="'编辑'"
                >编辑</btnComp
              >
              <btnComp btnType="text" @click="del(scope.row.id)" v-permission="'删除'">删除</btnComp>
            </template>
          </el-table-column>
        </tableComp>
        <add :isShow="addShow" @close="close" :title="title" :info="info" />
        <dialogComp
          title="提示"
          :isShow="hintShow"
          @close="close"
          @sure="sureDel"
        >
          <p>确定删除吗？</p>
        </dialogComp>
      </div>
    </div>
  </div>
</template>
<script>
import add from './add'
import { dictList, dictDel } from '@/api'
export default {
  name: '',
  props: {},
  components: { add },
  data () {
    return {
      addShow: false,
      hintShow: false,
      curPage: 1,
      pageSize: 10,
      total: 0,
      name: '',
      title: '',
      info: {},
      tableData: [],
      theadName: [
        { slot: 'operate' },
        { prop: 'name', label: '参数名称' },
        { prop: 'code', label: '标识符' },
        { prop: 'configValue', label: '参数值' },
        { prop: 'childNum', label: '参数值数量' },
        { prop: 'remark', label: '备注' }
      ]
    }
  },
  computed: {},
  watch: {},
  mounted () {
    this.getList()
  },
  methods: {
    getList () {
      const params = {
        curPage: this.curPage,
        pageSize: this.pageSize,
        level: 1,
        name: this.name
      }
      dictList(params).then((res) => {
        if (res.code === '1') {
          this.tableData = res.data.list
          this.total = res.data.totalCount
        }
      })
    },
    nameBlur (val) {
      this.name = val
    },
    search () {
      this.curPage = 1
      this.getList()
    },
    reset () {
      this.curPage = 1
      this.name = ''
      this.getList()
    },
    add (val, info) {
      this.title = val
      if (val === 'edit') {
        this.info = info
      }
      this.addShow = true
    },
    del (id) {
      this.id = id
      this.hintShow = true
    },
    detail (code, level) {
      this.$router.push({ name: 'params-dict-detail', query: { code, level } })
    },
    sureDel () {
      dictDel({ id: this.id }).then((res) => {
        if (res.code === '1') {
          this.$message.success('删除成功')
          this.close()
        }
      })
    },
    close () {
      this.addShow = false
      this.hintShow = false
      // this.sendShow = false
      this.getList()
    },
    handleSizeChange (val) {
      this.pageSize = val
      this.getList()
    },
    handleCurrentChange (val) {
      this.curPage = val
      this.getList()
    }
  }
}
</script>
<style lang='scss' scoped>
</style>
